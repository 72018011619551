/*
Language: Batch file (DOS)
Author: Alexander Makarov <sam@rmcreative.ru>
Contributors: Anton Kochkov <anton.kochkov@gmail.com>
Website: https://en.wikipedia.org/wiki/Batch_file
Category: scripting
*/

/** @type LanguageFn */
function dos(hljs) {
  const COMMENT = hljs.COMMENT(/^\s*@?rem\b/, /$/, {
    relevance: 10
  });
  const LABEL = {
    className: 'symbol',
    begin: '^\\s*[A-Za-z._?][A-Za-z0-9_$#@~.?]*(:|\\s+label)',
    relevance: 0
  };
  const KEYWORDS = ["if", "else", "goto", "for", "in", "do", "call", "exit", "not", "exist", "errorlevel", "defined", "equ", "neq", "lss", "leq", "gtr", "geq"];
  const BUILT_INS = ["prn", "nul", "lpt3", "lpt2", "lpt1", "con", "com4", "com3", "com2", "com1", "aux", "shift", "cd", "dir", "echo", "setlocal", "endlocal", "set", "pause", "copy", "append", "assoc", "at", "attrib", "break", "cacls", "cd", "chcp", "chdir", "chkdsk", "chkntfs", "cls", "cmd", "color", "comp", "compact", "convert", "date", "dir", "diskcomp", "diskcopy", "doskey", "erase", "fs", "find", "findstr", "format", "ftype", "graftabl", "help", "keyb", "label", "md", "mkdir", "mode", "more", "move", "path", "pause", "print", "popd", "pushd", "promt", "rd", "recover", "rem", "rename", "replace", "restore", "rmdir", "shift", "sort", "start", "subst", "time", "title", "tree", "type", "ver", "verify", "vol",
  // winutils
  "ping", "net", "ipconfig", "taskkill", "xcopy", "ren", "del"];
  return {
    name: 'Batch file (DOS)',
    aliases: ['bat', 'cmd'],
    case_insensitive: true,
    illegal: /\/\*/,
    keywords: {
      keyword: KEYWORDS,
      built_in: BUILT_INS
    },
    contains: [{
      className: 'variable',
      begin: /%%[^ ]|%[^ ]+?%|![^ ]+?!/
    }, {
      className: 'function',
      begin: LABEL.begin,
      end: 'goto:eof',
      contains: [hljs.inherit(hljs.TITLE_MODE, {
        begin: '([_a-zA-Z]\\w*\\.)*([_a-zA-Z]\\w*:)?[_a-zA-Z]\\w*'
      }), COMMENT]
    }, {
      className: 'number',
      begin: '\\b\\d+',
      relevance: 0
    }, COMMENT]
  };
}
module.exports = dos;