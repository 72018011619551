import { Component, EventEmitter, Input, Output } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'
import { AuthService } from '@app/auth/services/auth.service'
import { EquipmentsService, EquipmentUseRegulationRead, UserEquipmentReadList } from '@app/generated'
import { RegulationText } from '@app/settings/components/settings-regulations/settings-regulations.component'
import { LanguageService } from '@core/services/language.service'
import { ModalService } from '@core/services/modal.service'
import { NotificationService } from '@core/services/notification.service'
import MarkdownIt from 'markdown-it'
import { forkJoin } from 'rxjs'
import hljs from 'highlight.js'
import markdownItHighlightjs from 'markdown-it-highlightjs'
import { LanguageCode } from '@core/enums/language-code.enum'
import { FormControl, FormGroup } from '@angular/forms'

@Component({
	selector: 'epic-regulations',
	templateUrl: './user-regulations.form.html',
	styleUrl: './user-regulations.form.scss',
})
export class RegulationsForm {
	@Input() isModal = true
	@Output() itemsStatus = new EventEmitter<boolean>()
	userId?: string
	userEquipmentList: UserEquipmentReadList = { items: [], total: 0 }
	equipmentUseRegulations?: EquipmentUseRegulationRead
	languages: string[] = []
	language: string = 'en'
	regulationText?: RegulationText
	md!: MarkdownIt

	constructor(
		private modalService: ModalService,
		private authService: AuthService,
		private equipmentsService: EquipmentsService,
		private notificationService: NotificationService,
		private languageService: LanguageService,
		private sanitizer: DomSanitizer,
	) {}

	ngOnInit(): void {
		this.languages = this.languageService.getAcceptableLanguages
		this.language = localStorage.getItem('selected_language') as LanguageCode
		const userId = this.getUserId
		if (userId) {
			this.fetchData()
		}
	}

	get getUserId(): string {
		return this.authService.decodePayload()?.sub ?? ''
	}

	private fetchData(): void {
		const offset = undefined
		const limit = undefined
		const search = ''
		const showDeleted = false
		const sortOrder = 'desc'
		const sortBy: 'created_at' | 'updated_at' | 'deleted_at' | 'equipment_transfer_date' = 'updated_at'

		const userEquipments$ = this.equipmentsService.getUserEquipmentsForCurrentUser(
			offset,
			limit,
			search,
			showDeleted,
			sortOrder,
			sortBy,
		)

		const equipmentUseRegulations$ = this.equipmentsService.getCurrentlyApplicableEquipmentUseRegulations(
			undefined,
			undefined,
			undefined,
		)

		forkJoin([userEquipments$, equipmentUseRegulations$]).subscribe({
			next: ([userEquipmentsResponse, equipmentUseRegulationsResponse]) => {
				this.userEquipmentList = userEquipmentsResponse
				this.equipmentUseRegulations = equipmentUseRegulationsResponse
				this.initMarkdown()
				const text =
					this.language.slice(0, 2) === 'en'
						? this.equipmentUseRegulations.text
						: this.equipmentUseRegulations.translations?.find(t => t.language_code === this.language.slice(0, 2))
								?.text ?? ''

				this.regulationText = {
					form: new FormGroup({
						text: new FormControl(text ?? ''),
					}),
					countryCode: this.language.slice(0, 2),
					renderedMDHtml: this.sanitizer.bypassSecurityTrustHtml(this.md.render(text ?? '')),
				}
				this.updateRenderedMarkdown()
			},
			error: error => {
				if (error.status === 404) {
					// this.notificationService.error($localize`Failed to fetch data`)
				} else {
					this.notificationService.error($localize`Failed to fetch data`)
				}
			},
		})
	}

	checkItemsStatus(): void {
		const isNotEmpty = !!this.userEquipmentList.total
		this.itemsStatus.emit(isNotEmpty)
		console.log(isNotEmpty)
	}

	updateRenderedMarkdown(): void {
		if (this.regulationText) {
			this.regulationText.renderedMDHtml = this.sanitizer.bypassSecurityTrustHtml(
				this.md.render(this.regulationText.form.controls.text.value ?? ''),
			)
			setTimeout(() => {
				document.querySelectorAll('pre code').forEach(block => {
					hljs.highlightBlock(block as HTMLElement)
				})
			}, 0)
		}
	}

	acceptUsePolicy(): void {
		this.equipmentsService
			.acceptEquipmentUsePolicyAcceptance('body', false, {
				httpHeaderAccept: 'application/json',
			})
			.subscribe({
				next: () => {
					this.notificationService.success($localize`Equipment use policy accepted successfully.`)
					this.modalService.modalRef.close(true)
				},
				error: () => {
					this.notificationService.error($localize`Failed to accept equipment use policy.`)
					this.modalService.modalRef.close(false)
				},
			})
	}

	private initMarkdown(): void {
		this.md = new MarkdownIt({
			typographer: true,
			linkify: true,
			xhtmlOut: true,
			html: false,
		})
			.use(markdownItHighlightjs, { auto: true, code: true })
			.enable(['smartquotes', 'replacements', 'image'])
	}
}
