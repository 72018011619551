import { CommonModule } from '@angular/common'
import { Component, Input } from '@angular/core'
import { ClockComponent } from '@core/components/clock/clock.component'
import { DateComponent } from '@core/components/date/date.component'
import { ButtonComponent } from '@core/components/button/button.component'
import { Router } from '@angular/router'
import { LoaderService } from '@core/services/loader.service'
import { IconComponent } from '@core/components/icon/icon.component'

export interface HeadingButtonOptions {
	text: string
	link?: string
	action?: () => void
	headerIsHidden?: boolean
	iconName?: string
}

@Component({
	selector: 'epic-heading',
	standalone: true,
	imports: [CommonModule, ClockComponent, DateComponent, ButtonComponent, IconComponent],
	templateUrl: './heading.component.html',
	styleUrls: ['./heading.component.scss'],
})
export class HeadingComponent {
	@Input() text!: string
	@Input() disabled = false
	@Input() buttonOptions?: HeadingButtonOptions = undefined
	@Input() actionButtonOptions?: HeadingButtonOptions
	@Input() hideClock: boolean = false

	constructor(
		private router: Router,
		private loaderService: LoaderService,
	) {}

	async afterClick(): Promise<void> {
		if (this.buttonOptions) {
			if (this.buttonOptions.link) {
				const serializedUrl = this.router.serializeUrl(this.router.createUrlTree([this.buttonOptions.link]))
				if (serializedUrl != this.router.url) {
					this.loaderService.setContain().enable(async () => {
						await this.router.navigateByUrl(serializedUrl)
					})
				}
			} else if (this.buttonOptions.action) {
				this.buttonOptions.action()
			}
		}
	}
}
