@if (buttonOptions) {
	<div class="epic-heading__button-container">
		<epic-button
			size="small"
			(click)="afterClick()">
			<span class="row align__center">
				<epic-icon name="arrow-left"
				           class="margin__right--small" />
				{{ buttonOptions.text }}
			</span>
		</epic-button>
	</div>
}
@if (!buttonOptions || (buttonOptions && !buttonOptions?.headerIsHidden)) {
	<div
		*ngIf="!disabled"
		class="epic-heading"
		[class.with-button]="buttonOptions">
		<div class="epic-heading__title">
			<h1>
				{{ text }}
			</h1>
			@if (actionButtonOptions && actionButtonOptions.action) {
				<epic-button (click)="actionButtonOptions.action()">
					@if (actionButtonOptions.iconName) {
						<epic-icon [name]="actionButtonOptions.iconName" />
					}
					{{ actionButtonOptions.text }}
				</epic-button>
			}
			<ng-content></ng-content>
		</div>
		<div class="epic-heading__time-info"
		     *ngIf="!hideClock">
			<epic-clock />
			<epic-date />
		</div>
	</div>
}
